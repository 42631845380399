




























































































































































import {
PropType,
Ref,
computed,
defineComponent,
onMounted,
ref,
toRef,
} from '@nuxtjs/composition-api';
import {
SfAlert,
SfGallery,
SfLoader,
} from '@storefront-ui/vue';

import {
getName as getProductName,
getSwatchData as getProductSwatchData,
getProductSku,
} from '~/modules/catalog/product/getters/productGetters';

import { getConfigurableProductPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductPriceCommand';
import { getConfigurableProductSpecialPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductSpecialPriceCommand';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import { TabsConfig, useProductTabs } from '~/modules/catalog/product/composables/useProductTabs';
import { Product } from '~/modules/catalog/product/types';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import useWishlist from '~/modules/wishlist/composables/useWishlist';

import { arrowRightIcon } from '~/cms/helpers/customIcons';
import { expertAdviceUrl, getExpertAdviceLink } from '~/cms/helpers/urls';
import BKButton from '~/components/BK/BKButton.vue';
import BKHeadlineText from '~/components/BK/BKHeadlineText.vue';
import BKPrice from '~/components/BK/BKPrice.vue';
import ProductTeaser from '../../components/ProductTeaser.vue';

export default defineComponent({
  name: 'SimpleProduct',
  components: {
    SfGallery,
    SfLoader,
    SfAlert,
    BKButton,
    BKHeadlineText,
    BKPrice,
    ProductTeaser,
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const qty = ref(1);
    const product = toRef(props, 'product');

    const {
      addItem, error: cartError, loading: isCartLoading, canAddToCart,
    } = useCart();
    const { productGallery, imageSizes } = useProductGallery(product);
    const { activeTab, setActiveTab, openNewReviewTab } = useProductTabs();

    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist } = useWishlist();

    const productShortDescription = computed(
      () => props.product?.short_description?.html || '',
    );

    const leftImage = computed(() => (productGallery.value.length > 1 ? productGallery.value.pop()?.big.url : ''));

    const productConfiguration: Ref<{ size?: string }> = ref({});
    const productPrice = computed(() => getConfigurableProductPriceCommand(props.product));
    const productSpecialPrice = computed(() => getConfigurableProductSpecialPriceCommand(props.product));
    const addToCartError = computed(() => cartError.value?.addItem?.message);

    const customAttributes = computed(() => props.product.custom);

    const productInShop = computed(() => customAttributes.value?.exhibited_in_shop);

    const productStock = computed(() => props.product.quantity);
    const productOutOfStock = computed(() => productStock.value === 0);

    const noProductSelected = false;

    const descriptionHeadlineText = computed(() => ({
      headline: customAttributes.value?.kohl_description_headline,
      text: customAttributes.value?.kohl_description,
    }));

    const shortDescriptionHeadlineText = computed(() => ({
      headline: customAttributes.value?.kohl_short_description_headline,
      text: customAttributes.value?.kohl_short_description,
    }));

    const noteHeadlineText = computed(() => ({
      headline: customAttributes.value?.kohl_note_headline,
      text: customAttributes.value?.kohl_note,
    }));

    onMounted(() => {
      // This is a hard fix, to force the images to load in every browser
      document.querySelectorAll('.sf-image').forEach((element: HTMLElement) => {
        // eslint-disable-next-line no-param-reassign
        element.style.display = 'inline-block';
      });
      if (productGallery.value.length === 1) {
        // Force hiding the thumbnails
        const thumbsGallery = document.querySelectorAll('.sf-gallery__thumbs')[0] as HTMLElement;
        thumbsGallery.style.display = 'none';

        const primaryImage = document.querySelectorAll('.sf-gallery')[0] as HTMLElement;
        primaryImage.classList.add('sf-gallery--without-thumbs');
      }
    });

    const shippingUrl = '/hilfe-und-kontakt/versand-lieferung/';

    return {
      addItem,
      addItemToWishlist: addOrRemoveItem,
      canAddToCart,
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      isCartLoading,
      productConfiguration,
      productGallery,
      getProductName,
      getProductSwatchData,
      productPrice,
      productShortDescription,
      productSpecialPrice,
      qty,
      productInShop,
      productStock,
      imageSizes,
      setActiveTab,
      openNewReviewTab,
      activeTab,
      TabsConfig,
      addToCartError,
      descriptionHeadlineText,
      shortDescriptionHeadlineText,
      noteHeadlineText,
      customAttributes,
      arrowRightIcon,
      noProductSelected,
      leftImage,
      expertAdviceUrl,
      getExpertAdviceLink,
      shippingUrl,
      getProductSku,
      productOutOfStock,
    };
  },
  methods: {
    addItemToCart(quantity: number) {
      this.addItem({ product: this.product, quantity });
    },
  },
});
